<template>
  <modal width="70%" height="57%" class="video-modal" name="video-modal" @before-open="beforeOpen">
    <div slot="top-right">
      <button class="video-modal__close" @click="closeModal"><Cross /></button>
    </div>
    <iframe
        :src="videoUrl"
        :title="`video_${video}`"
        width="100%"
        height="100%"
        frameborder="0"
        allowfullscreen
    />
  </modal>
</template>

<script>
import Cross from '~/assets/icons/cross.svg?inline';

export default {
  name: 'VideoModal',
  components: {
    Cross,
  },
  data() {
    return {
      video: null,
    };
  },
  computed: {
    videoUrl() {
      return `https://www.youtube.com/embed/${this.video}`;
    },
  },
  methods: {
    beforeOpen(event) {
      if (event.params.video) {
        this.video = event.params.video;
      }
    },
    closeModal() {
      this.$modal.hide('video-modal');
      document.documentElement.style.overflow = 'auto';
    },
  },
};
</script>

<style lang="scss">
@import '~/assets/scss/atomic/components/_video-modal.scss';
</style>
