<template>
  <section>
    <div
        :id="!!id.length ? id : null"
        class="testimonials push-v--desktop--large push-v--tablet--large push-v--mobile--xxlarge"
        data-entrance="from-left"
    >
      <shape class="testimonials__background-shape" />
      <div class="testimonials__container container">
        <div class="row">
          <div v-swiper:mySwiper="swiperOption">
            <div class="swiper-wrapper">
              <div
                  v-if="title"
                  class="swiper-slide testimonials__heading"
              >
                <div class="testimonials__top-vectors">
                  <long-line class="testimonials__long-line" />
                  <quotes class="testimonials__quotes" />
                </div>
                <h2 class="testimonials__title">{{ title }}</h2>
                <h3 class="testimonials__subtitle">{{ subtitle }}</h3>
                <long-arrow class="testimonials__long-arrow" />
              </div>
              <div v-for="(item, index) in itemCollection" :key="index" class="swiper-slide">
                <TestimonialsCard v-bind="item" />
              </div>
            </div>
            <div slot="scrollbar" class="swiper-scrollbar" />
          </div>
        </div>
      </div>
    </div>
    <VideoModal />
  </section>
</template>

<script>
import getStatic from '~/utils/get-static';
import TestimonialsCard from '~/components/components/TestimonialsCard';
import VideoModal from '~/components/components/VideoModal';
import Shape from '~/assets/images/shapes/shape-green.svg?inline';
import Quotes from '~/assets/icons/quotes.svg?inline';
import LongArrow from '~/assets/icons/arrow_orange.svg?inline';
import LongLine from '~/assets/icons/line_orange.svg?inline';

export default {
  components: {
    TestimonialsCard,
    VideoModal,
    Shape,
    Quotes,
    LongLine,
    LongArrow,
  },
  props: {
    id: { type: String, default: '' },
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    callToVideoText: { type: String, default: '' },
    dragButtonText: { type: String, default: 'Drag' },
    items: {
      type: Array,
      default() {
        return [
          {
            title: 'Anna, 28',
            body: '<p>The collective has made me more outgoing and confident meeting new people</p>',
            image: {
              url: getStatic('/images/content/team/senior-management-1.png'),
              description: 'team member 1',
            },
          },
          {
            title: 'Max, 32',
            body: '<p>The collective has made me more outgoing and confident meeting new people</p>',
            image: {
              url: getStatic('/images/content/team/senior-management-1.png'),
              description: 'team member 1',
            },
          },
          {
            title: 'Adrian, 26',
            body: '<p>The collective has made me more outgoing and confident meeting new people</p>',
            image: {
              url: getStatic('/images/content/team/senior-management-1.png'),
              description: 'team member 1',
            },
          },
        ];
      },
    },
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 6,
        slidesOffsetBefore: 210,
        slidesOffsetAfter: 160,
        spaceBetween: 60,
        centerInsufficientSlides: true,
        keyboard: {
          enabled: true,
        },
        scrollbar: {
          el: '.swiper-scrollbar',
          draggable: true,
        },
        breakpoints: {
          425: {
            slidesPerView: 2,
            slidesOffsetBefore: 10,
          },
          768: {
            slidesPerView: 3,
            slidesOffsetBefore: 30,
          },
          991: {
            slidesOffsetBefore: 100,
            slidesPerView: 4,
            spaceBetween: 80,
          },
          1200: {
            slidesPerView: 3,
            slidesOffsetBefore: 100,
          },
          1440: {
            slidesPerView: 4,
            slidesOffsetBefore: 160,
          },
          1800: {
            slidesPerView: 5,
            slidesOffsetBefore: 160,
          },
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    };
  },
  computed: {
    itemCollection() {
      if (this.items && !this.items.length) {
        return [this.items];
      }

      return this.items;
    },
  },
};
</script>

<style lang="scss">
@import '~/node_modules/swiper/dist/css/swiper.css';
@import '~/assets/scss/atomic/modules/_testimonials.scss';
</style>
