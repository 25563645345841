<template>
  <div class="testimonials-card" :class="{ preloading: preloadingFlag}">
    <div class="np--all col-lg-12" tabindex="0">
      <h3 class="testimonials-card__title">{{ title }}</h3>
      <div class="testimonials-card__booking">{{ booking.length ? booking[0].name : null }}</div>
      <div v-if="body" class="testimonials-card__body text--faux col-lg-12 np--all" v-html="body" />
    </div>
    <div class="testimonials-card__wrapper push-v--desktop--medium push-v--tablet--small push-v--mobile--small">
      <div class="testimonials-card__image-container">
        <KenticoResponsiveImage :src="image.url" :alt="image.description" class="testimonials-card__image" :sizes="imageSizes" />
      </div>
      
      <a v-if="video && callToVideoText" tabindex="0" class="testimonials-card__button testimonials-card__play-button" @click="ctaClick">
        <play />
      </a>
      <call-to-action-button v-else-if="callToActionText" :external-link="externalLink" :call-to-action-link-text="callToActionText" :call-to-action-link="link" button-class="testimonials-card__button" />
    </div>
  </div>
</template>

<script>
import getStatic from '~/utils/get-static';
import CallToActionButton from '~/components/components/CallToActionButton';
import { BREAKPOINTS, BREAKPOINT_MD, BREAKPOINT_LG } from '~/utils/constants';
import { clickedPlayVideo } from '~/utils/gtm-events.js';
import Play from '~/assets/icons/play.svg?inline';

export default {
  components: {
    CallToActionButton,
    Play,
  },
  props: {
    callToVideoText: { type: String, required: false, default: 'Play Video' },
    callToActionText: { type: String, required: false, default: '' },
    externalLink: { type: String, default: '', required: false },
    link: {
      type: Object,
      default() {
        return {
          slug: '',
        };
      },
    },
    title: { type: String, required: true, default: '' },
    booking: { type: Array, required: true, default() {
      return [
        {
          codename: '',
          name: '',
        },
      ];
    },
    },
    body: { type: String, default: '' },
    video: { type: String, default: '' },
    image: {
      type: Object,
      required: true,
      default() {
        return {
          url: { type: String, required: true, default: getStatic('/images/content/team/senior-management-1.png') },
          description: { type: String, required: true },
        };
      },
    },
  },
  data() {
    return {
      preloadingFlag: true,
      imageSizes: {
        default: [300, 250],
        sizes: [
          { breakpoint: BREAKPOINTS[BREAKPOINT_MD], width: 300, height: 250 },
          { breakpoint: BREAKPOINTS[BREAKPOINT_LG], width: 400, height: 334 },
        ],
      },
    };
  },
  mounted() {
    this.preloadingFlag = false;
  },
  methods: {
    ctaClick() {
      clickedPlayVideo(this.$gtm, this.title);
      this.$modal.show('video-modal', {
        video: this.video,
      });
      document.documentElement.style.overflow = 'hidden';
    },
  },
};
</script>

<style lang="scss">
@import '~/assets/scss/atomic/components/_testimonials-card.scss';
</style>
