<template>
  <div v-if="callToActionLinkText">
    <button
        v-if="linkedModuleId"
        class="btn--dark-green"
        :class="buttonClass"
        @click.prevent="handleCtaClick"
    >
      {{ callToActionLinkText }} <Arrow class="btn--arrow__icon btn--arrow__icon--orange" />
    </button>
    <a
        v-else-if="externalLink"
        :href="externalLink"
        :class="buttonClass"
        target="_blank"
        class="btn--dark-green"
    >
      {{ callToActionLinkText }} <Arrow class="btn--arrow__icon btn--arrow__icon--orange" />
    </a>
    <nuxt-link
        v-else-if="showLinkButton"
        :to="callToActionLinkSlug"
        :class="buttonClass"
        class="btn--dark-green"
        @click.native="gatherData"
    >
      {{ callToActionLinkText }} <Arrow class="btn--arrow__icon btn--arrow__icon--orange" />
    </nuxt-link>

    <button
        v-else class="btn--dark-green"
        :class="buttonClass"
        @click.prevent="handleCtaClick"
    >
      {{ callToActionLinkText }} <Arrow class="btn--arrow__icon btn--arrow__icon--orange" />
    </button>
  </div>
</template>

<script>
import { getBookingStayPath, BOOKING_STAY, BOOKING_SHORT_STAY } from '~/utils/booking';
import pascalCase from 'pascal-case';
import { clickedCallToActionButton } from '~/utils/gtm-events.js';


export default {
  props: {
    buttonClass: { type: String, default: '' },
    externalLink: { type: String, default: '' },
    callToActionLinkText: { type: String, default: '' },
    linkedModuleId: { type: String, default: '' },
    callToActionLink: {
      type: Object,
      default: null,
    },
  },
  computed: {
    callToActionLinkSlug() {
      if (this.callToActionLink && typeof this.callToActionLink.slug !== 'undefined' && !!this.callToActionLink.slug) {
        return this.callToActionLink.slug;
      } else if (this.callToActionLink && this.callToActionLink.bookingType && this.callToActionLink.bookingType.length > 0) {
        const bookingType = this.callToActionLink.bookingType[0].codename;
        const bookingSlug = this.callToActionLink.bookingLocation[0].slug;

        switch (bookingType) {
          case BOOKING_STAY: {
            return getBookingStayPath(bookingSlug);
          }
          default: {
            console.error('booking type not found');
            return '';
          }
        }
      }
      return '';
    },
    showLinkButton() {
      if (this.callToActionLinkText && this.callToActionLink && this.callToActionLink.bookingType) {
        const bookingType = this.callToActionLink.bookingType[0].codename;
        if (bookingType === BOOKING_SHORT_STAY ) return false;
        return true;
      } else if (this.callToActionLinkText && this.callToActionLink) {
        return true;
      }
      return false;
    },
  },

  methods: {
    handleCtaClick() {
      if (this.linkedModuleId) {
        const element = document.getElementById(this.linkedModuleId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
          return;
        }
      }

      const mewsDistributor = this.callToActionLink.bookingLocation[0].bookingOptions.distributorId;


      // Get mews distributor
      Mews.Distributor({
        configurationIds: [mewsDistributor],
      }, (distributor) => {

        distributor.open();
        // code && distributor.setVoucherCode(code);
        // distributor.open();
        // distributor.setLanguageCode(languageCode);
        // distributor.setCurrencyCode(currencyCode);
        // distributor.setStartDate(date);
        // distributor.setEndDate(date);
        // distributor.setVoucherCode(code);
        // distributor.setRooms(rooms);
        // Singlehotel mode api calls
        // distributor.showRooms();
        // distributor.showRates(roomId);
        // Multihotel mode api calls
        // distributor.showHotels();
        // distributor.showRooms(hotelId);
        // distributor.setCity(cityId);


      });
    },
    gatherData() {
      const pascal = pascalCase(this.callToActionLinkText.toLowerCase());
      const action = `Click${pascal}`;
      clickedCallToActionButton(this.$gtm, action );
    },
  },
};
</script>

